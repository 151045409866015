<template>
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>
    <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et rerum
        reiciendis at, quia perferendis iur
    </p>
    <ol>
        <li>first item</li>
        <li>second item</li>
    </ol>
    <ul>
        <li>first item</li>
        <li>second item</li>
    </ul>
    <blockquote>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea est, natus,
        quis a opti
        <cite>- Lorem</cite>
    </blockquote>
    <p>There's a link point to <a href="https://google.com">bing.com</a></p>
    <table>
        <thead>
            <tr>
                <th>Company</th>
                <th>Contact</th>
                <th>Country</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Alfreds Futterkiste</td>
                <td>Maria Anders</td>
                <td>Germany</td>
            </tr>
            <tr>
                <td>Centro comercial Moctezuma</td>
                <td>Francisco Chang</td>
                <td>Mexico</td>
            </tr>
            <tr>
                <td>Ernst Handel</td>
                <td>Roland Mendel</td>
                <td>Austria</td>
            </tr>
            <tr>
                <td>Island Trading</td>
                <td>Helen Bennett</td>
                <td>UK</td>
            </tr>
            <tr>
                <td>Laughing Bacchus Winecellars</td>
                <td>Yoshi Tannamuri</td>
                <td>Canada</td>
            </tr>
            <tr>
                <td>Magazzini Alimentari Riuniti</td>
                <td>Giovanni Rovelli</td>
                <td>Italy</td>
            </tr>
        </tbody>
    </table>
</template>
